<template>
  <v-app>
    <v-app-bar dark app clipped-left dense color="primary">
      <h3 class="d-flex align-center">ridgewolf</h3>
      <v-spacer></v-spacer>
      <ProfilePopup></ProfilePopup>
    </v-app-bar>
    <v-main>
      <router-view></router-view>
    </v-main>
    <v-footer dark app dense color="primary">
      <v-col class="caption text-center" cols="12">
        Copyright &copy; {{ new Date().getFullYear() }} Ridgewolf Technologies,
        Inc. All rights reserved.
      </v-col>
    </v-footer>
  </v-app>
</template>

<script>
import ProfilePopup from './../components/ProfilePopup'

export default {
  name: 'PreLaunchLayout',
  components: {
    ProfilePopup
  }
}
</script>
